import { AppBar as MuiAppBar, Button, Container, SvgIcon, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material"
import { textAlign } from "@mui/system";

type IProps = {
    handleOpenRegister: VoidFunction
}

const AppBar = ({handleOpenRegister}: IProps) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const handleOpenWebApp = () => {
        window.location.replace('https://webapp.uptolog.com');
    }

    return <MuiAppBar position="static" sx={{
        mb: 5}}>
            <Toolbar disableGutters>
                <Typography
                    textAlign={mobile ? "center" : "left" }
                    flexGrow={1}
                    variant="h6"
                    noWrap
                    sx={{
                        mr: 2,
                        ml: 2,
                        [theme.breakpoints.up('sm')]: {
                            ml: theme.spacing(9),
                        }
                        // display: { xs: 'none', md: 'flex'},
                        // // fontFamily: 'monospace',
                        // fontWeight: 200,
                        // //letterSpacing: '.3rem',
                        // color: 'inherit',
                        // textDecoration: 'none',
                    }}
                >
                    UptoLog
                </Typography>
                {!mobile && <Button variant="outlined"
                    onClick={handleOpenRegister}
                    sx={{borderRadius: 25, minWidth: 200, alignSelf: "right", borderColor: theme.palette.common.white, mr: theme.spacing(4) }}>
                    <Typography sx={{color: theme.palette.common.white}}>S'enregistrer</Typography>
                </Button>
                }
                {!mobile && <Button variant="outlined"
                    onClick={handleOpenWebApp}
                    sx={{ minWidth: 200, alignSelf: "right", borderColor: theme.palette.primary.dark  }}>
                    <Typography sx={{color: theme.palette.common.white}}>Déja membre ? Se connecter</Typography>
                </Button>
                }
            </Toolbar>
    </MuiAppBar>
}

export default AppBar;