import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import AppBar from './AppBar';
import Features from "./Features";
import Links from "./Links";
import Product from "./Product";
import RegisterDialog from "./RegisterDialog";

const HomePage = () => {
    const intl = useIntl();
    const [openRegister, setOpenRegister] = useState(false);

    const handleOpenRegister = () => {
        setOpenRegister(true);
    }

    const handleCloseRegister = (result: boolean) => {
        setOpenRegister(false);
    }

    return (
        <Fragment>
            <AppBar handleOpenRegister={handleOpenRegister}/>
            <Product handleOpenRegister={handleOpenRegister}/>
            <Features/>
            <Links/>
            <RegisterDialog open={openRegister} handleClose={handleCloseRegister} />
        </Fragment>
    );
}

export default HomePage;