import { useEffect, useState } from "react";
import { TextField, StandardTextFieldProps  } from "@mui/material";
import { useIntl } from "react-intl";

type ValidationType = "required" | "email" | "password";

type IProps = {
  validationType: ValidationType
  submittedOnce?: boolean;
  onValidate: (id: string, value: boolean) => void
} & StandardTextFieldProps

export const ValidatedTextField = ({validationType, submittedOnce, onValidate, ...props}: IProps) => {
  const {value, id } = props;
  const intl = useIntl();
  
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    validate();
  }, [validationType, submittedOnce, value]);

  const validateEmail = (email: unknown) => {
    return String(email).toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const validatePassword = (password: unknown) => {
    return String(password).toLowerCase()
    .match(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    );
  }

  const validate = () => {
    if(validationType === "required" && value === "") {
      onValidate(id ?? "", false);
      setHelperText(intl.formatMessage({id: "validation.required"}));
      setError(true);
      return;
    }
    
    if(validationType === "email" && !validateEmail(value)) {
      onValidate(id ?? "", false);
      setHelperText(intl.formatMessage({id: "validation.email"}));
      setError(true);
      return;
    }
    
    if(validationType === "password" && !validatePassword(value)) {
      onValidate(id ?? "", false);
      setHelperText(intl.formatMessage({id: "validation.password"}));
      setError(true);
      return;
    }

    onValidate(id ?? "", true);
    setError(false);
    setHelperText("");
  }

  const showErrors = submittedOnce || (validationType !== "required" && value !== "");

  return <TextField {...props}
    error={error && showErrors}
    helperText={showErrors ? helperText : ''}
  >
    {props.children}
  </TextField>
}