import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Button, Fade, Grid, Hidden, Link, List, ListItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import { RegisterDialog } from './RegisterDialog';
import product from './product.png';

const LinksRoot = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    mt: 5,
    [theme.breakpoints.up('sm')]: {
        height: '30vh',
        //minHeight: 500,
        maxHeight: 1300,
    },
}));

const Links = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <LinksRoot>
            <Fade in>
                <Grid container spacing={4}>
                    <Grid item xs={12}
                        sm={6}
                        container
                        justifyContent="center">
                        <List>
                            <ListItem><Link><Typography>Contact</Typography></Link></ListItem>
                            <ListItem><Link><Typography>Mentions légales</Typography></Link></ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Fade>
        </LinksRoot>
    );
}

export default Links;