import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import HomePage from './Home/HomePage';


const theme = createTheme({
    palette: {
        primary: {
            contrastText: '#fff',
            dark: '#04062e',
            light: '#565ef0',
            main: '#0a0f71'
        },
        secondary: {
            contrastText: '#000',
            dark: '#7a4500',
            light: '#ffca85',
            main: '#ff9100'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
    },
    zIndex: {
        appBar: 1201
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1400,
            xl: 1600,
        },
    }
});

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<HomePage />}/>
                                {/* <Route path="*" element={<PageNotFound />} /> */}
                            </Routes>
                        </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}
