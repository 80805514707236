import * as React from 'react';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import AccountService from '../../services/AccountService';
import { ValidatedTextField } from '../../components/ValidatedTextField';
import { TransitionUp } from "../../components/Transition";
import { handleTextChange } from '../../functions/fieldChangeHandlers';

type IProps = {
    open: boolean;
    handleClose: (result: boolean) => void;
}

export const RegisterDialog = ({open, handleClose}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [registering, setRegistering] = React.useState(false);
    const [registered, setRegistered] = React.useState(false);
    const [alreadyKnown, setAlreadyKnown] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [validState, setValidState] = React.useState<{[key: string]: boolean}>({});
    const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [siret, setSiret] = useState<string>('');

    const isValid = () => !(["firstName", "lastName", "email", "companyName", "siret"].map(s => validState[s]).some(v => !v));

    const toggleValidation = (key: string, value: boolean) => {
        setValidState({...validState, [key]: value});
    }

    const handleSubmit = () => {
        setSubmittedOnce(true);
        setRegistering(true);

        if(!isValid()) {
            return;
        }

        // eslint-disable-next-line no-console
        AccountService.register({
            email: email,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            siret: siret
        }).then((resp) => {
            setRegistering(false);
            if(resp.ok) {
                setRegistered(true);
            } else if (resp.status == 409) {
                setAlreadyKnown(true);
            } else {
                setError(true);
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            TransitionComponent={fullScreen ? TransitionUp : undefined}>
            <DialogTitle>{registered ? "Enregistré" :intl.formatMessage({id: "signup"})}</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    {! registered && (<Box sx={{ m: 1, mt: 8 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <ValidatedTextField
                                    id="firstName"
                                    validationType="required"
                                    onValidate={toggleValidation}
                                    submittedOnce={submittedOnce}
                                    value={firstName}
                                    onChange={handleTextChange(setFirstName)}
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    label={intl.formatMessage({id: "firstname"})}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ValidatedTextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={intl.formatMessage({id: "lastname"})}
                                    name="lastName"
                                    autoComplete="family-name"
                                    validationType="required"
                                    onValidate={toggleValidation}
                                    submittedOnce={submittedOnce}
                                    value={lastName}
                                    onChange={handleTextChange(setLastName)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedTextField
                                    required
                                    fullWidth
                                    id="email"
                                    label={intl.formatMessage({id: "email"})}
                                    name="email"
                                    autoComplete="email"
                                    validationType="email"
                                    onValidate={toggleValidation}
                                    submittedOnce={submittedOnce}
                                    value={email}
                                    onChange={handleTextChange(setEmail)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedTextField
                                    required
                                    fullWidth
                                    id="companyName"
                                    label={intl.formatMessage({id: "companyname"})}
                                    name="companyName"
                                    autoComplete="companyName"
                                    validationType="required"
                                    onValidate={toggleValidation}
                                    submittedOnce={submittedOnce}
                                    value={companyName}
                                    onChange={handleTextChange(setCompanyName)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedTextField
                                    required
                                    fullWidth
                                    id="siret"
                                    label={intl.formatMessage({id: "siret"})}
                                    name="siret"
                                    autoComplete="siret"
                                    validationType="required"
                                    onValidate={toggleValidation}
                                    submittedOnce={submittedOnce}
                                    value={siret}
                                    onChange={handleTextChange(setSiret)}
                                />
                            </Grid>
                        </Grid>
                        <LoadingButton
                            loading={registering}
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 3, mb: 2, backgroundColor: registered ? theme.palette.success.main : theme.palette.primary.main}}
                        >
                            {registered ? "Enregistré" : intl.formatMessage({id: "signup"})}
                        </LoadingButton>
                        {alreadyKnown && <Typography color={theme.palette.error.main} >
                            Un utilisateur utilise déjà cette adresse email.
                            Déja inscrit ? <Link href='https://webapp.uptolog.com'>Authentifiez vous</Link>
                        </Typography>}
                        {error && <Typography color={theme.palette.error.main} >
                            Une erreur s'est produite. Veuillez rééssayer.
                        </Typography>}
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href='https://webapp.uptolog.com' variant="body2">
                                    {intl.formatMessage({id: 'haveaccount'}) + " " + intl.formatMessage({id: "signin"})}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>)}
                    {registered && (<Stack direction="column" sx={{alignItems: "center"}} spacing={2}>
                        <CheckCircleOutlineIcon sx={{ fontSize: 80, mb: 2 }} />
                        <Typography sx={{ maxWidth: 300, textAlign: "center"}}>Vous êtes inscrit avec succès. <br/>Vous devriez reçevoir prochainement une invitation pour vous connecter à votre adresse de contact : <b>{email}</b></Typography>
                        <Typography>Rien reçu ? Envoyez un email à <Link href="mailto:support@uptolog.com">support@uptolog.com</Link></Typography>
                    </Stack>)}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default RegisterDialog;
