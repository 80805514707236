import { UserRegisterData } from "../models/Account";

export class AccountService
{
    static async register(data: UserRegisterData) {
        const response = await fetch("/api/register", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        
        return response;
    }
}

export default AccountService;