import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';
import { Button, Fade, Grid, Hidden, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import PieChartIcon from '@mui/icons-material/PieChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EuroIcon from '@mui/icons-material/Euro';

const FeaturesRoot = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '40vh',
        //minHeight: 500,
        maxHeight: 600,
    },
}));

type IProps = {
    handleOpenRegister: VoidFunction
}

const Features = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FeaturesRoot>
            <Fade in>
                <Grid container spacing={4}>
                    <Grid item xs={12}
                        sm={4}
                        container
                        alignItems="center"
                        justifyContent="center"
                        >
                        <Stack direction="column" sx={{alignItems: "center", maxWidth: 300}}>
                            <PieChartIcon sx={{ fontSize: 40, mb: 2,[theme.breakpoints.up('xs')]: {
                                mt: theme.spacing(4),
                            }}} />
                            <Typography textAlign="center">Profitez de rapports détaillés sur votre activité. Avec une vision au quotidien de votre chiffre d'affaires.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item 
                        container
                        xs={12}
                        sm={4}
                        justifyContent="center"
                        sx={{ 
                            display: "flex", 
                            alignItems: "center"}}>
                        <Stack direction="column" sx={{alignItems: "center", maxWidth: 300}}>
                            <QueryStatsIcon sx={{ fontSize: 40, mb: 2 }} />
                            <Typography textAlign="center">Optimisez vos tournées.<br/> Identifiez vos activités les plus rentables et vos principaux postes de dépenses</Typography>
                        </Stack>
                    </Grid>
                    <Grid item 
                        container
                        xs={12}
                        sm={4}
                        justifyContent="center"
                        sx={{ 
                            display: "flex", 
                            alignItems: "center"}}>
                        <Stack direction="column" sx={{alignItems: "center", maxWidth: 300}}>
                            <EuroIcon sx={{ fontSize: 40, mb: 2}} />
                            <Typography textAlign="center">Catégorisez vos dépenses.<br/> Suivez les frais liés à votre flotte. <br/>Améliorez le suivi de votre facturation.</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Fade>
        </FeaturesRoot>
    );
}

export default Features;