import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Button, Fade, Grid, Hidden, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import { RegisterDialog } from './RegisterDialog';
import product from './product.png';

const ProductRoot = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '95vh',
        //minHeight: 500,
        maxHeight: 1300,
    },
}));

type IProps = {
    handleOpenRegister: VoidFunction
}

const Product = ({handleOpenRegister}: IProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ProductRoot>
            <Fade in>
                <Grid container spacing={4}>
                    <Grid item xs={12}
                        sm={6}
                        container
                        alignItems="center"
                        justifyContent="flex-end">
                        <Stack>
                            <Typography align="center" variant="h3">
                                Rejoignez Uptolog
                            </Typography>
                            <Typography
                                align="center"
                                variant="subtitle1"
                                sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}>
                                    Votre nouvelle plateforme de gestion d'exploitation pour sociétés transports.
                            </Typography>
                            <Typography align="center" variant="body1">
                                    Simplifiez le suivi votre activité.
                            </Typography>
                            <Typography align="center" variant="body1">
                                    Suivez en temps réel votre chiffre d'affaire.
                            </Typography>
                            <Typography align="center" variant="body1">
                                    Améliorez votre rentabilité.
                            </Typography>
                            <Typography
                                align="center"
                                variant="body2"
                                sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
                            >
                                Enregistrez-vous pour commencer votre essai gratuit.
                            </Typography>
                            <Button
                                onClick={() => handleOpenRegister()}
                                variant="outlined"
                                sx={{borderRadius: 25, minWidth: 300, alignSelf: "center" }}>
                                Je m'inscris
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item 
                        container
                        xs={12}
                        sm={6}
                        justifyContent={fullScreen ? "center" : "inherit"}
                        sx={{ 
                            display: "flex", 
                            alignItems: "center"}}>
                        <img src={product} alt='product' width="35%" height="auto"/>
                    </Grid>
                </Grid>
            </Fade>
        </ProductRoot>
    );
}

export default Product;