import * as React from 'react';
import { IntlProvider } from 'react-intl';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { i18nConfig } from '../config/i18n';
import { LanguageService } from '../services/LanguageService';

interface IIntlWrapperProps {
    children: JSX.Element;
}

class ConcreteIntlWrapper extends React.Component<IIntlWrapperProps, any> {
    public render() {
        const locale = LanguageService.get();
        return (
            <IntlProvider key={locale} locale={locale} messages={i18nConfig[locale]}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    {this.props.children}
                </LocalizationProvider>
            </IntlProvider>
        );
    }
}

export const IntlWrapper = (ConcreteIntlWrapper);
